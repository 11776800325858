<template lang="pug">
  div
    slot(
      name="activator"
    )
      v-btn(
        fab
        @click="addObject()"
        color="primary"
      )
        v-icon() mdi-plus
    v-dialog(
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      v-card(:disabled="loadingObjectSave" :loading="loadingObjectSave")
        v-toolbar(
          flat
          :max-height="$vuetify.breakpoint.smAndDown ? '56px' : '64px'"
        )
          v-btn(
            icon
            @click="closeDialog()"
          )
            v-icon mdi-close
          v-toolbar-title(v-if="edittingObjectId !== null")
            | {{ $t('item.edit', { item: edittingObject[objectTitleKey] }) }}
          v-toolbar-title(v-else) {{ $t('item.add', { item: objectText }) }}
          v-spacer
          v-toolbar-items
            v-btn(
              icon
              @click="saveObject()"
              color="primary"
            )
              v-icon mdi-content-save
        v-divider
        v-card-text(
          style="height: 600px;"
        )
          v-form.pt-4(
            v-model="validObject"
            ref="formObject"
          )
            slot(
              v-if="edittingObject"
              name="form"
              v-bind:edittingObject="edittingObject"
              v-bind:edittingObjectId="edittingObjectId"
            )
    ConfirmDialog(
      v-if="deletingObject"
      :dialog="deletingObject !== null"
      :title="$t('item.delete', { item: deletingObject[objectTitleKey] })"
      :text="$t('item.delete-confirm', { item: deletingObject[objectTitleKey] })"
      :loading="loadingObjectDelete"
      :confirmText="$t('ok')"
      :confirm="confirmDeleteObject"
      :cancel="closeDeleteObject"
    )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ConfirmDialog from '@/components/misc/ConfirmDialog.vue';

export default Vue.extend({
  name: 'ManageObject',
  components: {
    ConfirmDialog,
  },
  props: {
    defaultObject: {
      type: Object,
      required: true,
    },
    objectText: {
      type: String,
      required: true,
    },
    objectTitleKey: {
      type: String,
      required: true,
    },
    actionAdd: {
      type: String,
      required: true,
    },
    actionUpdate: {
      type: String,
    },
    actionDelete: {
      type: String,
    },
    modifyObjectBeforePush: {
      type: Function,
      default: (o) => o,
    },
  },

  data() {
    return {
      dialog: false,
      edittingObjectId: null,
      edittingObject: null,
      deletingObject: null,
      loadingObjectDelete: false,
      loadingObjectSave: false,
      validObject: true,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    addObject() {
      this.edittingObject = JSON.parse(JSON.stringify(this.defaultObject));
      this.$nextTick(() => {
        this.$refs.formObject.resetValidation();
      });
      this.dialog = true;
    },
    editObject(object) {
      this.edittingObjectId = object.id;
      this.edittingObject = JSON.parse(JSON.stringify({ ...this.defaultObject, ...object }));
      this.$nextTick(() => {
        if (this.$refs.formObject) {
          this.$refs.formObject.resetValidation();
        }
      });
      this.dialog = true;
    },
    resetObjectEdit() {
      this.edittingObject = JSON.parse(JSON.stringify(this.defaultObject));
      this.edittingObjectId = null;
      this.$refs.formObject.resetValidation();
      this.$emit('reset');
    },
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.resetObjectEdit();
      }, 200);
    },
    closeDeleteObject() {
      this.deletingObject = null;
    },
    deleteObject(object) {
      this.deletingObject = object;
    },
    confirmDeleteObject() {
      this.$emit('deleting', true);
      this.loadingObjectDelete = true;
      this.$store.dispatch(this.actionDelete, this.deletingObject)
        .then(() => {
          this.closeDeleteObject();
          this.$emit('deleted');
        })
        .catch((err) => {
          console.log('error', err);
        })
        .finally(() => {
          this.loadingObjectDelete = false;
          this.$emit('deleting', false);
        });
    },
    saveObject() {
      this.$refs.formObject.validate();
      this.$emit('saving', true);
      this.$nextTick(() => {
        if (!this.validObject) return;

        this.loadingObjectSave = true;
        const action = (this.edittingObjectId === null) ? this.actionAdd : this.actionUpdate;
        this.$store.dispatch(action, this.modifyObjectBeforePush(this.edittingObject))
          .then(() => {
            this.closeDialog();
            this.$emit('saved');
          })
          .catch((err) => {
            console.log('error', err);
          })
          .finally(() => {
            this.loadingObjectSave = false;
            this.$emit('saving', false);
          });
      });
    },
  },
});
</script>
