<template lang="pug">
  v-dialog(:value="dialog" persistent max-width="300")
    v-card(:loading="loading" :disabled="loading")
      v-card-title {{ title }}
      v-card-text {{ text }}
      v-divider
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="cancel()"
        ) {{ $t('cancel') }}
        v-btn(
          text
          color="primary"
          @click="confirm()"
        ) {{ confirmText }}
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ConfirmDialog',
  props: {
    dialog: Boolean,
    loading: Boolean,
    title: String,
    text: String,
    confirmText: String,
    confirm: Function,
    cancel: Function,
  },
});
</script>
