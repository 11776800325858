<template lang="pug">
  v-card
    v-subheader {{ $tc('groups.group', 2) }}
    v-card-text
      v-autocomplete(
        v-model="groupsLocal"
        :label="$t('groups.access')"
        :items="getCurrentUser.groups"
        :rules="[notEmptyRule]"
        required
        multiple
        chips
        deletable-chips
        @change="$emit('change-groups', groupsLocal)"
      )
        template(v-slot:selection="{ item, index }")
          v-chip(v-if="index === 0" small) {{ item.text }}
          span.grey--text.text-caption(v-if="index === 1")
            | (+{{ groupsLocal.length - 1 }} {{ $t('others') }})
      v-expand-transition
        v-btn.mt-4(
          v-if="groupsLocal.length !== getCurrentUser.groups.length"
          block
          color="primary"
          text
          @click="accessToAllMyGroups"
        )
          v-icon(left) mdi-check-all
          | {{ $t('groups.access-to-all') }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'GroupsAccess',
  model: {
    prop: 'groups',
    event: 'change-groups',
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      groupsLocal: [],
    };
  },
  mounted() {
    this.groupsLocal = [...this.groups];
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  methods: {
    accessToAllMyGroups() {
      this.groupsLocal = [...this.getCurrentUser.groups];
      this.$emit('change-groups', this.groupsLocal);
    },
  },
  watch: {
    groups(v) {
      this.groupsLocal = [...v];
    },
  },
});
</script>
